import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'

import Classes from './framework/classes.js'

const $classes = new Classes(process.env.VUE_APP_API_ROOT)

const app = createApp({
        ...App, 
        provide: $classes
    })
    .use(store)
    .use(i18n)
    .use(router)
    .mixin({
        methods: {
            webp: function (path) {
                return process.env.NODE_ENV  == 'production' && path.slice(path.length - 4, path.length) != 'webp' ? (path.slice(0, path.length - 4) + '.webp') : path;
            },
            stringToSlug: function (str, separator='-') {
                return str
                    .toString()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                    .toLowerCase()
                    .trim()
                    .replace(/[^a-z0-9 ]/g, '')
                    .replace(/\s+/g, separator);
            }
        }
    });

async function bootstrap() {
    try {
        await $classes.loadPublic();
    } catch (e) {
        console.log({...e});
    } finally {
        // app.mount('#app');
        app.mount('#app'); 
    }
}

bootstrap();

window.$classes = $classes;

export { $classes }