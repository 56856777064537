<template>
	<div 
		class="layout-default" 
		:class="{'layout-default--empty': this.$slots.default().length == 1}"
		id="layout"
	>
		<div class="loading">
			<div id="loading__container" v-if="showLoader">
				<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<p>{{$t('Loading')}}...</p>
			</div>
		</div>
		<slot />
	</div>
</template>

<script>
export default {
	data() {
		return {
			showLoader: false,
			delayLoader: 1000
		}
	},
    methods: {
        toggleLoader(){
			const self = this
			setTimeout(() => {
				self.showLoader = true;
			}, this.delayLoader);
        },
    },
	mounted(){
		this.toggleLoader();
	}
}
</script>

<style lang="scss" scoped>
.layout-default{
	.loading{
		display: none;
	}
	&--empty{
		height: 100vh;
		width: 100vw;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		div.loading{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
